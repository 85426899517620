header {
    background-color: #1E1E1E;
    color: white;
    padding: 1.5rem 0;
    text-align: center;
    font-size: 1.7rem;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.imgcon {
    width: 4rem;
    height: 5rem;
    display: flex;
    margin-left: 3rem;
    justify-content: center;
    align-items: center;
}

.fontawesomehead {
    width: 100%;
    height: 100%;
    color: #006FFF;
    display: inline-block;
    transition: transform 0.3s ease; /* Smooth transition for the jump */
}

.fontawesomehead:hover {
    color: rgb(3, 163, 163);
    cursor: pointer;
    transform: translateY(-5px); /* Moves the element up by 5px */
    transition: transform 0.3s ease; /* Smooth transition for the hover effect */
}

@keyframes neon-border {
    0%,
    100% {
        box-shadow: 0 0 10px transparent, 0 0 20px transparent, 0 0 30px transparent, 0 0 40px transparent, 0 0 50px transparent, 0 0 60px transparent, 0 0 70px transparent, 0 0 80px transparent;
    }
    50% {
        box-shadow: 0 0 10px #006FFF, 0 0 20px #006FFF, 0 0 30px #006FFF, 0 0 40px #006FFF, 0 0 50px #006FFF, 0 0 60px #006FFF, 0 0 70px #006FFF, 0 0 80px #006FFF;
    }
}

.buttoncon {
    width: auto;
    margin-right: 2rem;
}

.buttoncon button {
    width: 10rem;
    height: 3rem;
    border-radius: 1rem;
    outline: none;
    border: none;
    background-color: #006FFF;
    color: white;
    font-size: 1.4rem;
    font-weight: bold;
    transition: 0.2s all;
}

.buttoncon button:hover {
    cursor: pointer;
    background-color: rgb(3, 163, 163);
    transform: scale(1.05) translateY(-5px) rotate(-3deg);
    transition: transform 0.15s ease-in-out;
}

.nav-list {
    display: flex;
    justify-content: center;
}

.nav-list li {
    position: relative;
}

/* 4D2DB7 bg website */

.nav-list li a:hover, .dropdown p:hover {
    color: #006FFF;
    cursor: pointer;
}

.nav-list a, .dropdown p {
    color: white;
    font-weight: bold;
    padding: 10px 15px;
    display: inline-block;
    font-size: 115%;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #1E1E1E;
    min-width: 15rem;
    z-index: 1;
    border-radius: 1rem;
    z-index: 999999999;
}

.dropdown-content li {
    display: block;
}

.dropdown:hover .dropdown-content {
    display: block;
    margin-top: 0.1rem;
    box-shadow: 0 5px 5px 1px rgba(0, 0, 0, 0.2); /* White glow effect */
    transition: box-shadow 0.3s ease-in-out; /* Smooth transition */
}

.dropdown-content li p:hover {
    color: #006FFF;
}

.icon{
    color: white;
    width: 3.6rem;
    display: flex;
    padding-right: 2rem;
}

.hamburgertje{
    display: none;
}

.icon:hover{
    color: #006FFF;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    header {
        position: fixed; 
        top: 0;
        left: 0;
        width: 100%; 
        z-index: 9999; 
        justify-content: space-between; 
    }

    .nav-list {
        align-items: center;
        justify-content: center;
        flex-direction: column; 
        position: fixed;
        top: 0;
        left: 0;
        width: 60%; 
        height: 100vh; 
        background-color: #1E1E1E; 
        transform: translateX(-100%); 
        transition: transform 0.3s ease; 
    }

    .nav-list.open {
        transform: translateX(0); 
    }

    .nav-list li {
        margin: 5px 0;
    }

    .nav-list, .fontawesomehead {
        display: none; 
    }

    .hamburgertje {
        display: flex; 
    }
}