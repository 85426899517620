/* Basic Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Container styling */
.menu-container {
    position: relative;
}

/* Hamburger icon styling */
.menu-icon {
    position: fixed;
    top: 15px;
    left: 15px;
    display: inline-block;
    cursor: pointer;
    padding: 10px;
    z-index: 10000; /* Ensure it's above other elements */
}

.menu-bar {
    display: block;
    width: 30px;
    height: 3px;
    background-color: white;
    margin: 6px 0;
    transition: 0.3s;
}

/* Menu styling */
.menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 60vw;
    height: 100vh;
    padding-top: 10rem;
    background-color: #1E1E1E;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 9999;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
}

.menu.open {
    transform: translateX(0);
}

.menu ul {
    list-style: none;
    padding: 0;
    width: 100%;
}

.menu ul li {
    width: 100%;
}

.menu ul li a {
    display: block;
    padding: 20px;
    color: white;
    text-decoration: none;
    text-align: left;
    padding-left: 4rem;
}

.menu ul li a:hover {
    background-color: #555;
}

/* DROPDOWN */
/* Dropdown wrapper */
.dropdown-wrapper {
    position: relative;
    width: 100%;
}

/* Dropdown trigger styling */
.dropdown-trigger {
    cursor: pointer;
    width: 100%;
    padding: 20px;
    color: white;
    background: none;
    border: none;
    text-align: left;
    padding-left: 4rem;
    font-size: 1.7rem;
    transition: background-color 0.3s ease;
}

.dropdown-trigger:hover {
    background-color: #555;
}

/* Dropdown menu */
.dropdown-menu {
    display: none; 
    list-style: none;
    width: 100%;
    padding-left: 4rem;
}

.dropdown-menu li a {
    display: block;
    padding: 15px 20px;
    color: white;
    text-decoration: none;
    text-align: left;
}

.dropdown-menu li a:hover {
    background-color: #555;
}

/* Open the dropdown */
.dropdown-menu.open {
    display: block;
    animation: fadeInUp 0.3s ease forwards;
}

/* Simple fadeInUp animation */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Simple fadeInUp animation */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}



/* Menu bar animation for open state */
.menu-bar.open:nth-child(1) {
    transform: rotate(-45deg) translateY(13px);
    margin: 0;
}

.menu-bar.open:nth-child(2) {
    opacity: 0;
}

.menu-bar.open:nth-child(3) {
    transform: rotate(45deg) translateY(-13px);
}
