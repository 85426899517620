.homeContent {
    width: 100%;
    height: 100vh;
    background-color: #1E1E1E;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

.container {
    position: relative;
    display: inline-block;
}

.phone {
    display: none;
}

.eerste {
    font-size: 5rem;
    color: #353535;
}

.tweede {
    font-size: 7rem;
    color: #006FFF;
}

.derde {
    font-size: 5rem;
    color: #353535;
}

.vierde {
    font-size: 5rem;
    color: #006FFF;
}

.trans {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;
}

.tranq {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.vierde {
    display: flex;
    align-items: center;
    height: 1.5em;
    line-height: 1.5em;
}

.cursor-line {
    display: inline-block;
    width: 2px;
    height: 1.5em;
    background-color: #66b3ff;
    margin-left: 0.1rem;
    animation: blink 1s infinite;
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

/* ANIMATION */
@keyframes expandWidthThenHeight {
    0% {
        opacity: 0;
        transform: scaleX(0); /* Start scaled down in width */
        height: 100px; /* Initial height */
        background-size: 0% 100%; /* Start background at 0% width */
    }
    50% {
        opacity: 1; /* Fade in */
        transform: scaleX(1); /* Expand width to 100% */
        height: 100px; /* Maintain initial height */
        background-size: 100% 100%; /* Background grows to full width */
    }
    100% {
        transform: scaleX(1); /* Keep full width */
        height: 400px; /* Expand to final height */
        opacity: 1; /* Fully visible */
        background-size: 100% 100%; /* Background stays fully expanded */
    }
}

@keyframes moving-blue {
    0% { background-position: 0% 0%; }
    100% { background-position: 100% 100%; }
}

@keyframes fadeInText {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.grower {
    width: 60vw; 
    height: 100px; 
    border-radius: 5rem;
    overflow: hidden;
    background: #1E1E1E;
    z-index: 2;
    opacity: 0;
    transform: scaleX(0); 
    transform-origin: center; 
    animation: expandWidthThenHeight 1.5s ease forwards;
    box-shadow: 0 0 0px #006fff;
}

.trans {
    opacity: 0;
    animation: fadeInText 0.1s ease forwards;
    animation-delay: 0.3s;
}

.container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5rem;
    background-image: linear-gradient(90deg, #0033ff, #0099ff, #00ccff, #66b3ff, #cce0ff, #0033ff);
    background-size: 0% 100%; /* Start background size at 0% */
    animation: moving-blue 8s linear infinite, expandWidthThenHeight 1.5s ease forwards; /* Animate background with container */
    box-shadow: 0 0 15px #0033ff;
}

/* RESPONSIVE STYLING */

@media screen and (max-width: 1050px){
    .grower{
        width: 75vw;
    }
}

@media screen and (max-width: 850px){
    .grower{
        width: 80vw;
    }
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .homeContent {
        height: 100vh; 
        width: 100vw;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .grower {
        width: 100%;
        height: 70%;
        padding: 1rem;
    }

    .trans{
        max-width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tranq{
        width: 100%;
    }

    .container {
        width: 90%;
        height: auto;
    }

    .eerste, .tweede, .derde, .vierde {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
    }

    .eerste {
        font-size: 2.5rem;
    }

    .tweede {
        font-size: 3.5rem;
    }

    .derde, .vierde {
        font-size: 2.5rem;
    }

    .trans {
        gap: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .tranq {
        flex-direction: column;
        width: 100%;
    }

    .phone {
        display: block;
    }

    .pc {
        display: none;
    }
}
