* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    font-family: Arial, Helvetica, sans-serif;
}

a,
li {
    list-style: none;
    color: inherit;
    text-decoration: none;
} 