/* Scrollbar: */

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #1E1E1E;
}

::-webkit-scrollbar-thumb {
  background: #006FFF;
}

::-webkit-scrollbar-thumb:hover {
  background: #006FFF;
}
