.school {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #1e1e1e;
  min-height: 100vh;
}

.school__filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  position: relative; /* Ensure positioning of dropdown content */
  z-index: 9999999;
}

.dropdown-filters {
  display: flex;
  flex-direction: column;
  background-color: #333; /* Set a background color */
  border-radius: 2px; /* Add rounded corners */
  padding: 10px; /* Add padding for space around the items */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  width: auto; /* Set the width to auto, allowing it to adjust based on content */
  position: absolute; /* Position it absolutely so it doesn't push the page content */
  top: 110%;
  z-index: 999; /* Ensure the dropdown stays on top */
  min-width: 12rem;
}

.filters {
  margin: 5px 0; /* Add space between each filter */
}

.dropdown-filtertoggle {
  background-color: #333; /* Button background */
  color: #006fff; /* Button text color */
  padding: 10px; /* Padding for the button */
  border: none; /* Remove border */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 4px; /* Rounded corners */
  width: 12rem;
  font-weight: bold;
}

.filters {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.4rem;
  color: #006fff;
}

input[type="checkbox"] {
  transform: scale(1.2);
  margin-right: 0.5rem;
}

input[type="checkbox"]:hover{
  cursor: pointer;
}


.school__divcardcontainer{
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  background-color: inherit;
}

.school__card {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #006fff;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  color: #fff;
  text-align: center;
  transition: background-color 0.3s ease;
  height: 45rem;
  padding: 1rem;
}

.school__cardimgcontainer{
  width: 100%;
  min-height: 60%;
  max-height: 65%;
  display: flex;
  align-items: center;
}

.school__cardImage {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}

.school__cardContent {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.school__cardTitle {
  font-size: 2rem;
  margin-bottom: 10px;
}

.school__cardTags{
  padding-bottom: 2rem;
  font-size: 1.4rem;
}

.school__cardButton {
  display: inline-block;
  background-color: #006fff;
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  font-size: 1.1rem;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.3);
}

.school__cardButton:hover {
  background-color: #1e1e1e;
  cursor: pointer;
}

.close:hover{ 
  background-color: red;
}

/* POPUP */

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  color: white;
  padding: 3rem;
}

.popupContainer{
  width: 50%;
  height: 50%;
  max-height: 35rem;
  background-color: #006fff;
  display: flex;
  border-radius: 1rem;
}

.popup__content {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
}

.textContainer h2{
  text-align: center;
  font-size: 3rem;
  padding-bottom: 1.5rem;
}

.textContainer p{
  font-size: 1.1rem;
}

.selectedImgContainer{
  width: 50%;
  height: 100%;  
  padding: 2rem;
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.popup__cardImage{
  max-width: 100%;
  height: 100%;
  border-radius: 1rem;
}

.buttonscontainer{
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: center;
}

/* ANIMATION */
@keyframes expandAndFadeIn2 {
  0% {
      opacity: 0;
      transform: scaleX(0);
  }
  50% {
      opacity: 1;
      transform: scaleX(1);
      height: 200px; /* Adjust to a larger height for better content display */
  }
  100% {
      height: 50%; /* Allow height to grow dynamically */
      opacity: 1;
      transform: scaleX(1);
  }
}

.trans2 {
  opacity: 0;
  animation: fadeInText2 0.4s ease forwards; /* Longer duration for smoother fade */
  animation-delay: 1s; /* Delay for content to appear after container expands */
}

.grower2 {
  max-width: 100%;
  min-width: 700px;
  height: auto; /* Auto height to allow the content to expand */
  position: relative;
  border-radius: 2rem;
  overflow: hidden;
  background: #1E1E1E;
  z-index: 2;
  opacity: 0;
  transform: scaleX(0.1);
  animation: expandAndFadeIn2 1.2s ease forwards;
  padding: 20px; /* Padding for better content layout */
}

@keyframes fadeInText2 {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

/* Another: */
@keyframes cardFadeInUp {
  0% {
      opacity: 0;
      transform: translateY(-15px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

/* CLOSING ANIMATION */
@keyframes collapseToCenter {
  0% {
      opacity: 1;
      transform: scale(1);
  }
  100% {
      opacity: 0;
      transform: scale(0.1);
  }
}

.grower2.closing {
  animation: collapseToCenter 0.8s ease forwards; /* Adjust duration for smoother animation */
}

.school__card {
  opacity: 0; /* Initially hidden */
  transform: translateY(-15px);
  animation: cardFadeInUp 0.5s ease forwards;
}

@media screen and (max-width: 768px){
  .popup {
    width: 100%;
    min-height: 100vh;
    height: auto;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }
  
  .popupContainer{
    width: 80%;
    max-height: none;
    height: auto;
    background-color: #1e1e1e;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    overflow: hidden;
  }
  
  .popup__content {
    width: 100%;
    height: auto;
  }
  
  .textContainer h2{
    font-size: 3rem;
    padding-bottom: 1.5rem;
  }
  
  .textContainer p{
    font-size: 1.7rem;
  }
  
  .selectedImgContainer{
    width: 100%;
    height: 25%;  
    padding: 2rem;
  }
  
  .popup__cardImage{
    max-width: 100%;
    height: auto;
    border-radius: 1rem;
  }
  
  .buttonscontainer{
    width: 100%;
    display: flex;
    gap: 2rem;
    justify-content: center;
  }
  .grower2{
    min-width: 100%;
  }
  .school{
    margin-top: 8rem;
  }
  .textContainer p{
    font-size: 0.9rem;
  }

  .school__cardTags{
    font-size: 1.2rem;
  }

  .school__filters{
    position: fixed;
    top: 50px;
  }
  
}