.skills {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: #1e1e1e;
  padding: 20px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 4rem;
}

.skills__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  list-style: none;
  gap: 2rem;
  text-align: center;
  padding: 1rem;
  flex-wrap: wrap;
}

.skills__content {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #006fff;
  border-radius: 10px;
  padding: 20px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  width: 30rem;
  height: 25rem;
  opacity: 0;
  transform: translateY(-15px);
  animation: fadeInUp 0.5s ease forwards;
}

.skills__content:hover {
  background-color: #1e1e1e;
}

.skills__contentImgContainer {
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
  overflow: hidden;
  border-radius: 1rem;
}

.skills__contentIMG {
  width: 100%;
  height: auto;
  border-radius: 1rem;
}

.skills__contentTitle {
  color: #fff;
  font-size: 1.5rem;
  margin: 10px 0;
}

.skills__contentInfo {
  color: #fff;
  text-align: center;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px){
  .skills{
    margin-top: 7rem;
  }
}

/* ANIMATION */

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.skills__content:nth-child(1) {
  animation-delay: 0.1s;
}

.skills__content:nth-child(2) {
  animation-delay: 0.15s;
}

.skills__content:nth-child(3) {
  animation-delay: 0.2s;
}

.skills__content:nth-child(4) {
  animation-delay: 0.25s;
}

.skills__content:nth-child(5) {
  animation-delay: 0.3s;
}

.skills__content:nth-child(6) {
  animation-delay: 0.35s;
}

.skills__content:nth-child(7) {
  animation-delay: 0.4s;
}

.skills__content:nth-child(8) {
  animation-delay: 0.45s;
}