footer {
    width: 100%;
    height: 11rem;
    background-color: #1E1E1E;
    display: flex;
    justify-content: center;
}

footer > div {
    width: 80vw;
    height: auto;
    border-top: 1px solid #006FFF;
}

.copy{
    width: 100%;
    color: white;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    font-size: 1.6rem;
}