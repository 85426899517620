.contact {
    width: 100%;
    height: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #1E1E1E;
}

.contact-container {
    width: 100%;
    min-height: 100%;
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.contact-info {
    width: 90rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.contact-info div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #333;
    border-radius: 5px;
    color: white;
    font-size: 1.6rem;
    text-transform: uppercase;
    opacity: 0;
    animation: slideInFromLeft 0.5s ease-out forwards;
}

.contact-info div span {
    font-weight: bold;
    height: 5rem;
    display: flex;
    align-items: center;
    padding: 1rem;
}

.contact-info div a {
    color: #006FFF;
    text-decoration: none;
    font-size: 90%;
}

.contact-info div a:hover {
    text-decoration: underline;
    color: rgb(3, 163, 163);
}

/* Responsive styles */
@media (max-width: 1200px) {
    .contact-info {
        width: 80%;
    }
}

@media (max-width: 992px) {
    .contact-info {
        width: 90%;
    }

    .contact-info div {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 768px) {
    .contact-info div {
        font-size: 1.4rem;
        padding: 0.8rem;
    }

    .contact-info div span {
        height: auto;
        padding: 0.8rem;
    }

    .contact-info div a {
        font-size: 90%;
        padding-left: 2rem;
    }

    .contact{
        min-height: 95vh;
    }
}

@media (max-width: 576px) {
    .contact-info div {
        font-size: 1.2rem;
        padding: 0.6rem;
    }

    .contact-info div span {
        font-size: 1.5rem;
    }

    .contact-info div a {
        font-size: 90%;
    }
}

/* ANIMATION */
@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Delay animation for each contact item */
.contact-info div:nth-child(1) {
    animation-delay: 0.1s;
}
.contact-info div:nth-child(2) {
    animation-delay: 0.2s;
}
.contact-info div:nth-child(3) {
    animation-delay: 0.3s;
}
.contact-info div:nth-child(4) {
    animation-delay: 0.4s;
}
.contact-info div:nth-child(5) {
    animation-delay: 0.5s;
}
